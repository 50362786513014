import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const NikkeGuidesPhantom: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Phantom guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_phantom.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Phantom Guide & Review</h1>
          <h2>A guide & review for Phantom in NIKKE: Goddess of Victory.</h2>
          <p>
            Last updated: <strong>13/10/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Phantom" />
              <div className="employee-container for-nikke">
                <NikkeCharacter mode="icon" slug="phantom" enablePopover />
              </div>
              <p>
                The thief outcast of Master Hand who unfortunately missed out on
                the fun because ShiftUp randomly decided to launch Solo Raid one
                day earlier this time. She is a Water B3 DPS from Elysion who
                uses cards to inflict debuffs on enemies and collect buffs for
                herself. She can later consume these buffs and debuffs to deal
                additional damage. Her special move, Secret Trick: Rampage of
                Thieves, allows her to deal a good amount of Distributed Damage.
              </p>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                In short, she is weak. Her kit looks mint at first glance, but
                then you realize there are several glaring problems: low basic
                attack ratio, long reload times, skills requiring more than one
                target to be optimal, mediocre Burst Skill, and many other
                problems that will quickly surface the moment you start
                analyzing her skills.
              </p>
              <p>
                Let's first talk about her DPS. Mathematically, against single
                targets, she deals less damage than Scarlet. Phantom's low
                innate basic attack ratio, in tandem with her inability to
                trigger some of her skills, results in an underwhelming MPS
                (multiplier per second). The worst part is, whereas Scarlet can
                still be "reinstated into meta" by deploying SAnis with her,
                Phantom does not have an enabler yet. Even Bunny Alice, who can
                technically refresh her stacks against single targets, will not
                lead to a massive uptick in damage output.
              </p>
              <p>
                Things become slightly better when calculating her damage
                against multiple targets. This is because she can naturally and
                repetitively inflict Calling Card and constantly removing them
                to deal Additional Damage. However, to continually inflict this
                damage, you must spread your shots among multiple enemies, which
                means she is far from being auto-friendly. A minimum of 3
                enemies is needed, otherwise with 2 enemies, you are forced to
                wait 5 seconds between triggers.
              </p>
              <h5>Should I pull this unit?</h5>
              <p>
                We think the answer is a resounding: <strong>NO</strong>. The
                major reason? Nikke's 2nd Anniversary is VERY close, but that's
                not all. Phantom is a non-limited character, which goes in our
                favor in that there is no serious harm skipping her since we can
                simply wishlist once she enters the default pool. What is even
                more in our favor is the fact that her use in the meta is, in
                fact, limited.
              </p>
              <p>
                While she can be used in all formats, she does not match top
                units in almost any scenario, especially in ease-of-use. Sure,
                she might see use in Water-Weak Raids and obviously Elysion
                Tower, where she likely shines the most due to having many mobs.
                However, we believe she will not immensely improve your scores
                or the number of floors you manage to push. With Red Hood having
                set the standard in the first anniversary, the chance of the
                upcoming anniversary unit to be broken is extremely high.
              </p>
              <p>
                If you do decide to pull her because you are preparing for
                Water-Weak Raids and/or Elysion Tower pushing, we strictly
                advise you NOT to use your Golden Mileage for her, since she is
                absolutely not worth the Mileage.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <p>
                {' '}
                Before we dwell deep into her kit, please take a look at this
                peak gameplay clip, which represents the idle game NIKKE (they
                say).
              </p>
              <Row className="video">
                <Col xs={12} lg={4}>
                  <YoutubeEmbed embedId="IMpN7tK_bW0" />
                </Col>
              </Row>
              <p>
                Theoretically, she can deal more damage than Scarlet: Black
                Shadow. The problem is: how she can achieve that?
              </p>
              <h5>Thifes' Calilgn Kda</h5>
              <blockquote>
                <p>
                  ■ Activates when hitting a Rapture with a normal attack if the
                  Rapture is not in Calling Card status. Affects the target.
                </p>
                <p>Calling Card: DEF ▼ 32.19% for 5 sec.</p>
                <p>
                  ■ Activates when hitting a Rapture with a normal attack if the
                  Rapture is not in Calling Card status. Affects self.
                </p>
                <p>
                  Thief's Dagger: Hit Rate ▲ 25.75%, stacks up to 3 time(s) and
                  lasts for 5 sec.
                </p>
                <p>
                  ■ Activates when hitting a target with a normal attack if the
                  target is in Calling Card status. Effects self.
                </p>
                <p>Attack damage ▲ 75.17% for 1 round(s).</p>
              </blockquote>
              <p>
                Whenever Phantom hits a Rapture without the debuff Calling Card,
                she inflicts them with Calling Card for 5s and obtains one stack
                of the buff Thief's Dagger for 5s, up to 3 stacks. The debuffed
                Rapture has lowered DEF, whereas Phantom gains increased Hit
                Rate. Since this skill requires hitting a non-debuffed Rapture
                to stack, it is technically impossible to achieve maximum stacks
                of Thief's Dagger without having multiple mobs. Therefore, to be
                optimal, this skill requires hitting 2-3 different Raptures
                (NIKKEs don't count), but you still get bonus ATK DMG if hitting
                a Rapture with Calling Card over and over again. The procedure
                is:
              </p>
              <ul>
                <li>
                  Hit a non-debuffed Rapture to inflict Calling Card and gain 1
                  stack of Thief's Dagger. Hitting a Rapture which has Calling
                  Card will not gain her Thief's Dagger but instead increases
                  her ATK DMG.
                </li>
                <li>Accumulate 3 stacks of Thief's Dagger.</li>
                <li>
                  Upon reaching max stacks of Thief's Dagger, up to 3 enemies
                  who are inflicted with Calling Card will take Additional
                  Damage, removing the debuff.
                </li>
                <li>
                  The buff Thief's Dagger is then cleared, and Phantom gains
                  Distributed DMG buff from Skill 2.
                </li>
              </ul>
              <p>
                The kit feels pretty complicated because it forces you to switch
                between enemies to maximize its potential. On one side, you can
                keep spray-firing to abuse the Additional Damage as much as
                possible. On the other hand, you can also keep firing at one
                target to abuse the ATK DMG buff aforementioned.{' '}
              </p>
              <p>
                In terms of Hit Rate, Phantom will need at least 2 stacks of
                Thief's Dagger to match Quency: Escape Queen's Hit Rate. This
                means against solo bosses, she will have less Hit Rate than
                Quency and thus not be able to core-hit as much as QEQ. Before
                testing, we thought the HR at maximum (75%) was great, but soon
                we realized that the Hit Rate basically cleared itself the
                instant it reached 3 stacks, so the maximum she can maintain is
                only 2 stacks. And EVEN THEN, she requires 2 different Raptures
                to maintain. Most of the time, it's 1 stack so she's just a
                worse QEQ here.
              </p>

              <Alert variant="primary">
                <p>
                  Using Bunny Alice, you can circumvent the need of multiple
                  Raptures present to stack Thief's Dagger. However, this may
                  lead to slot pressure, as it might just be better to just
                  bring another DPS. If a new unit with stack refresh who suits
                  Phantom better is released, things might change for the good.
                </p>
              </Alert>
              <h5>Tfieh's Vsiion</h5>
              <blockquote>
                <p>
                  ■ Activates when Thief's Dagger is fully stacked. Affects the
                  target(s) in Calling Card status after the stacks are removed.
                </p>
                <p>
                  Deals 84.33% of final ATK as additional damage. Calling Card
                  status is removed after the effect is triggered.
                </p>
                <p>
                  ■ Activates when Thief's Dagger is fully stacked. Affects self
                  after the stacks are removed
                </p>
                <p>
                  Distributed Damage ▲ 12.86% continuously, stacks up to 3
                  time(s). Stacks are removed after Burst Skill is cast.
                </p>
                <p>
                  ■ Activates after landing 10 normal attack(s). Effects self.
                </p>
                <p>ATK▲ 85.12% for 5 sec.</p>
                <p>Distributed Damage ▲ 31.92% for 10 sec.</p>
              </blockquote>
              <p>
                This is where we enjoy the peak idle gameplay - (Spoiler Alert:
                you don't)! This skill answers why Skill 1 stacks are cleared
                immediately by the time it reaches 3 - Phantom will detonate the
                debuff on all Raptures (lol it's 3), deals some bonus damage on
                them, then give herself a whatever amount of Distributed Damage
                ▲ buff. This buff can stack up to 3 times so you need to
                detonate at least 3 times to reach this max, then it will add up
                and clear itself when Phantom burst. The buff is meh, let's take
                a look at the bonus damage.
              </p>
              <ul>
                <li>Situation: Over 6.3s.</li>
                <li>
                  Scarlet: Black Shadow hits 9 times, dealing 9 x 85.93% ATK +
                  1500.94% ATK ~ 773.37% ATK + 1500.94% ATK.
                </li>
                <li>
                  Phantom hits 75 times, dealing 25 x 15.78% + 84.33% ATK x 25 ~
                  394.5% ATK + 2108.25% ATK.
                </li>
                <li>
                  SBS has ATK buff, Charge DMG buff, and shorter damage interval
                  during her burst.
                </li>
                <li>
                  Phantom can deal that much damage with Attack Damage + ATK
                  buff on any burst.
                </li>
              </ul>
              <p>
                As you can see, Phantom output is very high and stable, in
                exchange for your mentality. You need to
              </p>
              <ul>
                <li>Find 3 Raptures.</li>
                <li>
                  Switch between them every 0.083s (because AR shoots 12 ammo
                  per sec).
                </li>
                <li>
                  Or pause between each hit and move your crossfire to next
                  target.
                </li>
              </ul>
              <p>Overall, it's just peak. Thank you, Ingrid.</p>
              <Alert variant="primary">
                <p>
                  Just like Skill 1, this skill also has a strong self buff
                  which is ATK ▲ and Distributed Damage ▲ buffs after only 10
                  shots. It's very easy to achieve and maintain and should be
                  the main focus, not the peak gameplay above.
                </p>
              </Alert>
              <h5>Screet Trikc: Rmapaeg of Tiehves!</h5>
              <blockquote>
                <p>■ Affects all enemies.</p>
                <p>Deals 1457.28% of final ATK as Distributed Damage.</p>
              </blockquote>
              <p>
                A very meh burst, likely more meh than Scarlet's (because
                Scarlet's wipe is undivided). To be fair, her Burst Skill damage
                is even worse than Quency: Escape Queen, so you don't have to
                rely on her Nuke to screen-wipe in PVP either. You burst because
                it's Phantom's turn, but she's perfectly line working as an
                off-burst DPS.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Usage Analysis and Tier List" />
              <h5>
                Campaign (LD) - <strong className="s">S</strong> | Campaign (HD)
                - <strong className="a">A</strong>
              </h5>
              <p>
                Phantom expresses interest in dealing serious damage in this
                game mode, but do try to understand that she is extremely hard
                and annoying to use. On Water-Weak Stages, phantom can
                effectively be used as a DPS and may even be used as an
                off-burst DPS. The idea is to pause spam and change your cursor
                position to hit different raptures, as shown in the video clip
                at the start of this guide. At the same time, you can burst with
                Red Hood or Modernia or SBS or Asuka or whoever you want to, but
                NOT Alice! The problem here is that manual intensive units will
                interfere with other manual intensive units. Phantom requires
                extreme babysitting (manual gameplay) to work, same as Alice.
                Hence, it is better to use units that don't require manualing
                alongside Phantom; some prominent names have already been told
                above.
              </p>
              <p>
                While Phantom is a good shout for Water-Weak Stages, with
                absolute perfect gameplay and build, she may even be a choice
                for neutral element stages. Taking everything into
                consideration, Phantom is by far the best B3 unit for Elysion
                Tower, and if you want to actually tryhard the tower, Phantom IS
                the way to go.
              </p>
              <h5>
                Bossing - <strong className="a">A</strong>
              </h5>
              <p>
                We will be judging this Nikke based on two major points: Boss
                (Solo) and Boss (Adds), with the major focus being on Boss
                (Solo) since it's far more prevalent and relevant. Phantom wants
                multiple Raptures on the screen to reach her peak, or well,
                reach any peak at all. She is considerably weaker compared to
                the likes of Ludmilla: Winter Owner and Quency: Escape Queen
                against Solo Bosses, all because of the fact that she cannot
                stack her thieving ability if only a single target rapture
                exists on the screen. That said, she should still deal enough
                DPS if properly built (skills and OL lines) to fit in a
                Water-Weak Solo Raid, so if you plan to tryhard, Phantom gets in
                your Top 5 teams.
              </p>
              <Alert variant="primary">
                <p>
                  She will not be a fit absolutely anywhere in Neutral Element
                  Solo Raids, unless adds are extremely prevalent on the boss,
                  which brings us to our other point.
                </p>
              </Alert>
              <p>
                Phantom closes in with her water partners (xLud and QeQ) when
                bosses with adds are considered, since she can now stack up her
                Thief's Dagger ability. With this, she can possibly (with highly
                managed gameplay and with enough Raptures on the screen, of
                course) reach to new heights, and compete with others in terms
                of overall damage dealt. Lucky for her, Harvester exists.
                Unlucky for her, Quency and XLudmilla with Liter and Crown are
                enough to reach Stage 9 on Harvester!
              </p>
              <Alert variant="primary">
                <p>
                  We've probably stated this before. From the average player's
                  perspective, Phantom is not the Nikke you should be pulling!.
                </p>
              </Alert>
              <h5>
                PVP - <strong className="b">B</strong>
              </h5>
              <p>
                Phantom is not a good unit for PvP since her Nuke is too weak
                and her single target damage is towards the lower end. Her Skill
                1 does not work in PvP since there are no Raptures. Because of
                this, she is definitely bench material in the overall meta. Even
                so, you may still use her as a Nuke + Follow-Up Damage Dealer
                with Blanc if you have an incomplete PvP roster.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <p>
                Phantom is your average DPS, and hence ATK and Elemental Damage
                are highly appreciated on her. Some Ammo rolls on her would
                allow her to reload less (her reload time is 2.0s+). If you plan
                to play perfectly with her in Campaign-type stages or on Bosses
                (with adds) stages, then we also suggest getting some Hit Rate
                to maximize core hits as well.
              </p>
              <ul>
                <li>
                  <strong>Essential:</strong> 1-2× Max Ammo
                </li>
                <li>
                  <strong>Ideal:</strong> 4× Elemental DMG, 4× ATK, 1-2× Max
                  Ammo
                </li>
                <li>
                  <strong>Passable:</strong> 2× Hit-Rate, 1-2× CRIT DMG, 1-2×
                  CRIT Rate, 2× Ammo
                </li>
                <li>
                  <strong>Priority:</strong> Low
                </li>
              </ul>
              <h5>Skill Investments</h5>
              <ul>
                <li>
                  <strong>Skill 1: 4→7→10</strong>
                </li>
                <ul>
                  <li>
                    {' '}
                    Skill 1 provides Phantom with a permanent uptime Attack
                    Damage buff, as well as a decent DEF down on the target. If
                    adds are present, it will also buff her Hit Rate
                    considerably, making this skill a high priority upgrade.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 4→7→10</strong>
                </li>
                <ul>
                  <li>
                    {' '}
                    Skill 2 provides, yet again, a high value offensive buff
                    with permanent uptime. It is an ATK buff this time, diluting
                    the amount of buffs she may get from ATK buffers as well. If
                    you are fighting Bosses with Adds, this skill also gives her
                    a Distributed Damage buff, and if you remember the skill
                    analysis, can be a major source of damage output for Phantom
                    - but only if you are able to perfectly play with Phantom on
                    such stages. All in all, very high priority skill to upgrade
                    for Phantom.
                  </li>
                </ul>
                <li>
                  <strong>Burst Skill: 4→7</strong>
                </li>
                <ul>
                  <li>
                    {' '}
                    Her Burst Skill is the least part of her damage output. You
                    can leave it at low values, but may upgrade it to 10 if you
                    plan to minmax damage for raids, or if you are building
                    Phantom for PvP for some reason.
                  </li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="phantom" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_3_sm.png"
                alt="Guide"
              />
              <StaticImage
                src="../../../images/nikke/generic/cube_4_sm.png"
                alt="Guide"
              />
              <p>Resilience and Bastion are the way to go.</p>
              <p>
                Phantom has no special gimmicks. Resilience or Bastion fit her
                perfectly just fine. Bastion allows her to hit more bullets
                overall usually, but if Reload Speed buffers like Privaty or
                Crown are in the team, Resilience Cube is the better choice.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: A Wet Crown</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="ludmilla-winter-owner"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="quency-escape-queen"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="phantom" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                {' '}
                Crown, with a Trifecta of Water Units. Liter/RedHood act as
                Burst 1 Units. Possible team for water weak campaign stages.
                Phantom's burst damage isn't much so it can be ignored, but she
                can deal immense damage with her Skill 2 with her 'perfect
                gameplay style'. With tons of Elemental OL, this team can
                possibly be a good choice for Water Weak stages.
              </p>
              <h5>
                Team #2: Schoolgirls are introduced a new career path: Robbery
              </h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="tia" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="naga" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="phantom" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
              </div>
              <p>
                {' '}
                General Team 1 for Phantom. She can fit in the Tia-Naga team.
              </p>
              <h5>Team #3: Coin Loving 777 meets a Thief</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rouge" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="phantom" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="modernia" enablePopover />
                    <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                {' '}
                General Team 2 for Phantom, since she, like most DPS, fits in
                the Rouge-Blanc or Blanc-Noir combo
              </p>
              <h5>Team #4: Wo'ah Water Woteh</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="bay-goddess-mary"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="quency-escape-queen"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="phantom" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="ludmilla-winter-owner"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                This team utilizes SMary's Elemental DMG buff to make QEQ more
                devastating, as well as her healing to keep the team healthy.
                The last two units are FLEX and can be other units, damage
                dealers & buffers alike. We use Phantom and XLud to showcase 3
                Water DPS.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="PvP Team Compositions" />
              <h5>Team #1: The Nuke Team for Nukers</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="phantom" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                {' '}
                3RL Nuke. Prioritizes buffing Phantom to the max with both RH's
                Burst 1, and Blanc's Burst 2. Blanc will allow Phantom to deal
                any follow-up damage she can muster up the courage to deal.
              </p>
              <h5>Team #2: Zombie Queen</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="phantom" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="noir" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                {' '}
                3RL Nuke. Aim is to revive Blanc when she eventually dies in P1,
                and opposing RLs should allow Phantom to get Indomitability from
                Blanc. This should pull off the nuke alongside any follow up
                damage Phantom can dish out.
              </p>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Rare good Elysion DPS (good for Elysion standard). Her
                          competitors are Maid Privaty and Guillotine.
                        </li>
                        <li>
                          Burst Skill is not too powerful, and she doesn't rely
                          on it to deal damage, making her possible to run as an
                          off-burst unit.
                        </li>
                        <li>
                          Better core-hit rate than ordinary AR thanks to Hit
                          Rate buff.
                        </li>
                        <li>
                          Fits well into Elysion's team because they don't
                          provide any buffs, and Phantom has every buff.
                        </li>
                        <li>Decent Water DPS.</li>
                        <li>Peak Idle Nikke gameplay at its finest!</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Extremely. Manual. Intensive. For Campaign and Boss
                          with Adds stages.
                        </li>
                        <li>
                          Her damage drops drastically against single targets.
                        </li>
                        <li>
                          Outside Elysion Tower and Water-Weak Raid, she likely
                          has no place.
                        </li>
                        <li>
                          Wrong timing; 2nd Anniversary is at the doorstep.
                        </li>
                        <li>
                          Wee shuold neto taht she coudltn spell prpperly.
                        </li>
                        <li>
                          If Alice wears out your Left-Click button, Phantom
                          will wear out your ESC button. The cost of getting a
                          new keyboard is not worth the malding.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-5">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesPhantom;

export const Head: React.FC = () => (
  <Seo
    title="Phantom Guide & Review | NIKKE | Prydwen Institute"
    description="A guide & review for Phantom in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
